<template>
  <r-box class="addurls">
    <r-section
      :label="$t('addUrls.title')"
      class="pb-4"
    >
      <v-row>
        <v-col
          sm="12"
          class="pt-0 mt-5"
        >
          <span>{{ $t('addUrls.addURL') }}</span>
        </v-col>
      </v-row>
      <v-row
        v-for="(item, index) in items"
        :key="`addurls-textfield-${index}`"
        class="addurls--item py-0 my-0"
      >
        <v-col
          sm="12"
          class="py-0"
        >
          <r-text-field
            class="addurls--textfield"
            :placeholder="`${$t('global.abbreviations.example')}: https://amazon.com/dp/ASIN`"
            :value="items[index].url"
            :rules="validUrlsExcludingLast ? [] : urlItem"
            style="width:100%"
            :validate-on-blur="true"
            :error-message="item.serverValidationError"
            :hint="getLoadTimeMessageOfAUrl(items[index].url)"
            @change="(value) => onTextInputChange(index, value)"
          />
          <v-icon
            v-if="items.length > 1"
            class="addurls--delete"
            @click="onClickRemove(index)"
          >
            delete
          </v-icon>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col>
          <r-button
            class="addurls--addmore"
            icon="mdi-plus"
            :label="$t('global.actions.addMore')"
            :outlined="true"
            :loading="fetchingValidateUrls"
            :disabled="!allValid"
            @click="onClickAddMore"
          />
        </v-col>
      </v-row>
    </r-section>
    <r-section
      :label="$t('addUrls.csvUpload.title')"
      class="pb-4"
    >
      <r-file-upload
        class="addurls--fileupload"
        :label="$t('componentLibrary.uploadFileLabel')"
        :placeholder="$t('componentLibrary.uploadFilePlaceholder')"
        :filetypes="['.txt', '.csv']"
        :select-file-label="$t('componentLibrary.uploadFileSelectLabel')"
        :upload-button-label="$t('componentLibrary.uploadButtonLabel')"
        @upload="onFileUpload"
      />
    </r-section>
    <v-row class="mt-5">
      <v-col>
        <label>{{ $t('resources.project') }}</label>
        <project-select
          class="addurls--selectproject mt-1"
          @change="onChangeSelectedProject"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12">
        <r-error-message
          v-if="fetchCreateUrlsError"
          class="addurls--errormessage"
          :errors="[fetchCreateUrlsError]"
        />
      </v-col>
      <v-col class="d-flex justify-end align-center">
        <r-button
          class="addurls--cancel mr-2"
          :label="$t('global.actions.cancel')"
          :outlined="true"
          @click="$router.push('/data')"
        />
        <r-button
          class="addurls--save"
          :label="$t('global.actions.save')"
          :disabled="!validUrlsExcludingLast || fetchingValidateUrls"
          :loading="fetchingCreateUrls"
          @click="onClickSave"
        />
      </v-col>
    </v-row>
  </r-box>
</template>

<script>
import RBox from '@/components/library/molecules/RBox'
import RButton from '@/components/library/atoms/RButton'
import RTextField from '@/components/library/molecules/RTextField'
import RSection from '@/components/library/molecules/RSection'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import ProjectSelect from '@/components/app/data/ProjectSelect'
import RFileUpload from '@/components/library/molecules/RFileUpload'
import Vue from 'vue'
import { mapActions, mapGetters, mapState } from 'vuex'
import isValidUrl from '@/utils/isValidUrl'
import getLoadTimeMessageOfUrl from '@/utils/getLoadTimeMessageOfUrl'

const includeHttps = (url) => `https://${url.replace(/^https?:\/\//, '')}`
export default {
  name: 'AddUrls',
  components: {
    RBox,
    RButton,
    RSection,
    RTextField,
    RErrorMessage,
    ProjectSelect,
    RFileUpload,
  },
  data() {
    return {
      items: [{ url: '' }],
      urlItem: [
        (value) => !!value || this.$i18n.t('global.validations.messages.required'),
        (value) => isValidUrl(value) || this.$i18n.t('global.validations.messages.invalidURL'),
      ],
      projectId: '',
      csvFileUploadedLength: 0,
    }
  },
  computed: {
    ...mapGetters('projects', [
      'selectedProjectId',
    ]),
    ...mapState('urls', [
      'fetchingCreateUrls',
      'fetchCreateUrlsError',
      'validateUrlsErrors',
      'validUrls',
      'fetchingValidateUrls',
    ]),
    ...mapState('projects', [
      'projects',
    ]),
    allValid() {
      return this.items.every(this.isValidItem)
    },
    validUrlsExcludingLast() {
      return this.itemsToSave.every(this.isValidItem)
    },
    itemsToSave() {
      const items = [...this.items]
      // We do not save the last item if it is empty.
      if (items.length > 1 && !items.at(-1).url) {
        items.pop()
      }
      return items
    },
  },
  mounted() {
    this.$data.projectId = this.selectedProjectId
  },
  methods: {
    ...mapActions('urls', [
      'createURLs',
      'validateURLs',
    ]),
    isValidItem({ url }) {
      return isValidUrl(url)
    },
    getLoadTimeMessageOfAUrl(url) {
      return getLoadTimeMessageOfUrl(url)
    },
    onTextInputChange(index, { value: url }) {
      const item = this.items[index]
      const newItem = {
        ...item,
        url,
      }

      Vue.set(this.items, index, newItem)
    },
    onClickRemove(index) {
      this.items = this.items.filter((_, i) => i !== index)
    },
    onFileUpload({ data }) {
      let lines = data.split('\n').filter((datum) => !!datum)
      lines = lines.map((line) => line.replace('\r', ''))
      const urlItemsFromCSVFile = lines.map((line) => ({ url: line }))
      this.$data.items = this.$data.items.filter(({ url }) => !!url).concat(urlItemsFromCSVFile)
      this.runServerSideValidation()
      this.$data.csvFileUploadedLength += urlItemsFromCSVFile.length
    },
    resetServerSideValidation() {
      this.items = this.items.map((item) => ({
        ...item,
        url: item.url ? includeHttps(item.url) : '',
        serverValidationError: '',
      }))
    },
    async runServerSideValidation() {
      this.resetServerSideValidation()

      const urls = this.itemsToSave.map(({ url }) => url)
      await this.validateURLs({ urls })
      if (this.validateUrlsErrors.length) {
        this.validateUrlsErrors.forEach(({ urlIndex, message }) => {
          this.items[urlIndex].serverValidationError = message
        })
      } else {
        this.items = this.validUrls.map((url) => ({ url }))
      }
    },
    async onClickAddMore() {
      await this.runServerSideValidation()
      if (!this.validateUrlsErrors.length) {
        this.items = this.items.concat({ url: '' })
      }
    },
    async onClickSave() {
      await this.runServerSideValidation()
      if (this.validateUrlsErrors.length) {
        return
      }

      const urls = this.itemsToSave.map(({ url }) => url)

      const { projectId } = this.$data

      if (projectId) {
        await this.createURLs({ urls, projectId })
      } else {
        await this.createURLs({ urls })
      }

      if (!this.fetchCreateUrlsError) {
        this.$router.push('/data')
      }
    },
    onChangeSelectedProject({ value }) {
      this.projectId = value
    },
  },
}
</script>

<style scoped>
.addurls {
  width: 640px;
  min-height: 60%;
  margin: 0 auto;
}

.addurls--item {
  position: relative;
}

.addurls--delete {
  position: absolute;
  top: 13.7px;
  right: 15px;
}
</style>
